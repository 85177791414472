import React, { Component } from 'react';
import './Leaderboard.css';
import "./home.css";


export class Leaderboard extends Component {
    static displayName = Leaderboard.name;

    constructor(props) {
        super(props);
        this.state = { users: [], loading: true };
    }
    componentDidMount() {
        this.fetchLeaderboard();
    }

    static renderLeaderboardTable(users, highlightedUsername) {
        return (
            <div className="leaderboard-table-wrapper">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Poäng</th>
                        </tr>
                    </thead>
                    <tbody className="leaderboard-table-body">
                        {users.map((user, index) => (
                            <tr key={index} className={(user.username === highlightedUsername) ? 'highlighted-row' : ''}>
                                <td>{index + 1}</td>
                                <td>{user.username}</td>
                                <td>{user.highscore}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        const username = sessionStorage.getItem('username');

        if (username == null) {
            window.location.href = "/";
        }

        let contents = this.state.loading
            ? <p className="loading-message"><em>Loading...</em></p>
            : Leaderboard.renderLeaderboardTable(this.state.users, username);

        return (
            <div className='background'>
                <div className='leaderboardbody'>
                    <div className='leaderboard'>
                        <div className="leaderboard-heading">
                            <p className="bigBlackL">Ledartavlan:</p>
                        </div>
                        {contents}
                        <br />
                    </div>
                    <div className='button-container'>
                        <button className='button' onClick={this.handleBackClick}>
                            <p className="whiteL">TILLBAKA</p>
                        </button>
                    </div>
                </div>
            </div>
        );

    }
    handleBackClick = () => {
        window.location.href = "/gameover";
    }

    async fetchLeaderboard() {
        try {
            const response = await fetch('https://api.husmanspel.se/GetLeaderboard');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            this.setState({ users: data, loading: false });
        } catch (error) {
            console.error('Error fetching leaderboard data:', error);
            // Optionally, you can update the state to indicate an error occurred
            this.setState({ loading: false });
        }

    }
}