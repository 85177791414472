import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Admin.css'; // Import the CSS file

export const ChangePW = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChangePW = async (event) => {
    event.preventDefault();
    const { OldPw, NewPw1, NewPw2 } = event.target.elements;

    const token = sessionStorage.getItem('token')
    if (!token) {
      navigate('/admin');
      return;
    }

    if (NewPw1.value === NewPw2.value) {
      try {
        const response = await fetch(`https://api.husmanspel.se/ChangePassword?newPw=${encodeURIComponent(NewPw1.value)}&oldPw=${encodeURIComponent(OldPw.value)}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) {
          setError('Password successfully changed');
        } else {
          setError('Invalid password');
        }
      } catch (error) {
        setError('An error occurred. Please try again later.');
      }
    } else {
      setError('The passwords do not match');
    }
  };

  return (
    <div className="admin-container">
      <div className="admin-form-container">
        <div>
          <h2>Byt lösenord:</h2>
          <form className="admin-login-form" onSubmit={handleChangePW}>
            <div>
              <label htmlFor="OldPw">Gammalt Lösenord:</label>
              <input type="password" id="OldPw" name="OldPw" />
            </div>
            <div>
              <label htmlFor="NewPw1">Nytt Lösenord:</label>
              <input type="password" id="NewPw1" name="NewPw1" />
            </div>
            <div>
              <label htmlFor="NewPw2">Återupprepa nytt Lösenord:</label>
              <input type="password" id="NewPw2" name="NewPw2" />
            </div>
            <button type="submit">Byt Lösenord</button>
            {error && <p className="admin-error-message">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};
