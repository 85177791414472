import React from 'react';
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory

import './vilkor.css';
import backgroundImg from "../images/villkor.png";

const Vilkor = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }

    return (
        <div className="modal-dialog modal-md">
            <div className="modal-content" style={{ backgroundImage: `url(${backgroundImg})` }}>
                <div className="modal-header">
                    <h2 className="modal-title" id="rulesModalLabel" style={{ color: "black", textAlign: "center" }}>Regler & Villkor</h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClick}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h3>KAMPANJEN ARRANGERAS AV:</h3>
                    <address>
                        Husmangames<br />
                        Sandtorpsvägen 4<br />
                        149 45 Nynäshamn<br />
                        Sverige
                    </address>
                    <p>Frågor gällande tävlingen kan skickas till <a href="mailto:info@svenskhusman.se">info@svenskhusman.se</a></p>
                    <p>Genom att delta i Svensk Husman Spel accepterar du nedan regler och samtycker till att dina personuppgifter behandlas enligt GDPR. All persondata kommer att hanteras konfidentiellt och enligt svensk lag.</p>

                    <h2>DELTAGANDE I SVENSK HUSMAN SPELET</h2>
                    <p>Svensk Husman är öppen för personer som är bosatta i Sverige. Är deltagaren under 18 år behövs målsmans godkännande. Anställda hos företaget som har medverkat i planering och utveckling av detta spel och kampanj får inte delta.
                        Vi tillåter endast en registrering av konto per e-post adress. Samma person får spela hur många gånger de vill men kan endast vinna vardera vinsten en (1) gång.
                        Kampanjen Svensk Husman Spelet pågår mellan de datum som visas på hemsidan.</p>
                    <h2>SÅ HÄR DELTAR DU:</h2>
                    <ol>
                        <li>Registrera din email för att kunna spela på kampanjsidan.</li>
                        <li>För att spela Svensk Husman Spelet måste du registrera ett användarnamn, vilket kommer att synas på topplistan samt på vinnarlistan.</li>
                        <li>Användarnamnet får bara innehålla små bokstäver</li>
                        <li>Användarn namnet får inte innehålla något mellanslag eller skällsord.</li>
                        <li>Alla som registrerar sig kan spela hur många gånger man vill.</li>
                        <li>Spelet går ut på att samla så många lussebullar som möjligt och undvika hinder, varje lussebulle ger ett poäng</li>
                        <li>Med tillräkligt höga poäng kan en rabbatkupong vinnas innan kampanjen är slut.</li>
                        <li>När kampanjen är slut lottas en vinnare ut.</li>
                        <li>Vinnarna meddelas direkt när tävlingen är avslutas via ett e-postmeddelande samt att de publiceras under vinnare (menyval) med sitt användarnamn.</li>
                        <li>Alla som registrerar sig får spela hur många gånger man vill under kampanjperioden.</li>
                    </ol>
                    <h2>VINSTER:</h2>
                    <h4>Dagliga vinster</h4>
                    <p>Rabattkoder på SvenskHusman.se</p>
                    <h2>HUR MOTTAR DU DIN VINST:</h2>
                    <p>Vinnarna kontaktas via e-post, högst en vecka efter kampanjen är slut. Vinsten skickas via e-post till den angivna emailadressen som vinnaren har registrerat.</p>
                    <p>Eventuell vinstskatt bekostas av vinnaren.</p>
                    <p>Vinnarna publiceras på kampanjsidan med användarnamn och datum, när man vunnit.</p>
                    <p>Vinsterna kan ej bytas mot kontanter eller andra vinster. Vinsterna är personliga och kan ej överlåtas till annan person.</p>
                    <h2>ARRANGÖREN TAR INTE ANSVAR:</h2>
                    <p>Arrangören tar inte ansvar för eventuella tekniska svårigheter eller problem som kan påverka endera anmälan eller slutresultatet av denna tävling.</p>
                    <p>Arrangören ansvarar inte för tredje parts handlande eller försummelse.</p>
                    <h3>ARRANGÖREN FÖRBEHÅLLER SIG RÄTTEN TILL ATT:</h3>
                    <ul>
                        <li>Vid behov uppdatera villkoren under kampanjperioden. Vid fortsatt deltagande anses eventuella ändrade villkor vara godkända.</li>
                        <li>Stänga av en användare utan förvarsel vid olämpligt beteende eller misstänkt fusk.</li>
                        <li>När som helst avbryta denna kampanj utan att därigenom ådra sig något ansvar.</li>
                    </ul>
                    <p>Personer som bryter mot dessa kampanjvillkor kommer att uteslutas ur kampanjen.</p>
                </div>
            </div>
        </div>
    );
}

export default Vilkor;



