import { EventBus } from '../EventBus';
import Phaser from 'phaser';
import { Scene } from 'phaser';

let pauseButton;

export class Game extends Scene {
    // objekt initiering
    player;
    obstacles;
    treeObstacles;
    points;
    cursors;
    gyllenebulle = false;

    // poäng
    gameOver = false;
    score = 0;
    scoreText;
    maxObstacles = 5;
    maxPoints = 5;

    // rörelsefaktorer
    movementSpeed = 6;
    sideSpeed = 360;

    //intervall för objekt
    pointSpawnInterval;
    obstacleSpawnInterval;
    obstacleTreeSpawnInterval;

    //ljud 
    startSound;
    playsound;
    stopSound;

    isGamePaused = false;

    constructor() {
        super('Game');
    }

    preload() {
        this.loadAssets();
    }

    loadAssets() {
        this.load.spritesheet('lusse', 'assets/lusse2.png', {
            frameWidth: 60,
            frameHeight: 60
        });
        this.load.image('sten', 'assets/sten.png', {
            frameWidth: 53,
            frameHeight: 48
        });
        this.load.image('point', 'assets/score.png'/*, {
            frameWidth: 34,
            frameHeight: 48
        }*/);
        this.load.image('treeObstacle', 'assets/wSnow.png'/*, {
            frameWidth: 50,
            frameHeight: 60
        }*/);
        this.load.spritesheet('gyllenebulle', 'assets/gyllenetest1.png', {
            frameWidth: 44.2,
            frameHeight: 60
        });
        this.load.image('avsluta', 'assets/avsluta.png');
        this.load.image('playButton', 'assets/startknapp.png');

        //älg
        this.load.spritesheet('moose', 'assets/moose.png', {
            frameWidth: 64,
            frameHeight: 48
        });

        // ljudfiler
        this.load.audio('startSound', 'assets/start.mp3');
        this.load.audio('playsound', 'assets/8bit.mp3');
        this.load.audio('stopSound', 'assets/stop.mp3');
    }

    create() {
        // bakgrund
        this.createBackground();

        this.createPlayer();

        this.createAnimations();

        this.createInputListeners();

        this.createGroups();

        this.createScoreText();

        this.createPausebutton();

        this.playSounds();

        this.addPhysics();

        this.spawnObjects();

        EventBus.emit('current-scene-ready', this);
    }

    createBackground() {
        const canvas = this.add.graphics();
        canvas.fillStyle(0xFFFAFA, 1);
        canvas.fillRect(0, 0, this.cameras.main.width, this.cameras.main.height);
        canvas.setDepth(-1);
    }

    createPlayer() {
        const playerX = this.cameras.main.width / 2; // halva skärmen
        const playerY = 100; // fixad y position
    
        this.player = this.physics.add.sprite(playerX, playerY, 'lusse');
        this.player.setSize(40, 40);
        this.player.setBounce(0);
        // sätt till sant för att inte tillåta spelaren åka utanför
        this.player.setCollideWorldBounds(false);
    }
    

    createAnimations() {
        //gyllenebulle animation
        this.anims.create({
            key: 'spin',
            frames: this.anims.generateFrameNumbers('gyllenebulle', { start: 0, end: 4 }),
            frameRate: 10,
            repeat: -1
        });

        //älg
        this.anims.create({
            key: 'runningmoose',
            frames: this.anims.generateFrameNumbers('moose', { start: 0, end: 2 }),
            frameRate: 10,
            repeat: -1
        });

        // spelaranimationer
        this.anims.create({
            key: 'left',
            frames: this.anims.generateFrameNumbers('lusse', { start: 0, end: 0 }),
            frameRate: 10,
            repeat: -1
        });

        // spelaranimationer
        this.anims.create({
            key: 'straight',
            frames: this.anims.generateFrameNumbers('lusse', { start: 1, end: 1 }),
            frameRate: 10,
            repeat: -1
        });

        this.anims.create({
            key: 'right',
            frames: this.anims.generateFrameNumbers('lusse', { start: 2, end: 2 }),
            frameRate: 10,
            repeat: -1
        });
    }

    createInputListeners() {
        this.cursors = this.input.keyboard.createCursorKeys();
        this.input.addPointer(2);
    }

    createGroups() {
        this.points = this.physics.add.group();
        this.obstacles = this.physics.add.group();
        this.treeObstacles = this.physics.add.group();
        this.trees = this.physics.add.group();
    
        let obstacle1 = this.obstacles.create(0, 0, 'sten');
        let obstacle2 = this.obstacles.create(0, 0, 'sten');
    
        let treeObstacle1 = this.treeObstacles.create(0, 0, 'treeObstacle');
        let treeObstacle2 = this.treeObstacles.create(0, 0, 'treeObstacle');
    
        [obstacle1, obstacle2].forEach(obstacle => {
            obstacle.setOrigin(0.5, 0.5);
            obstacle.body.setSize((obstacle.displayWidth * 0.8), (obstacle.displayHeight * 0.8));
        });
    
        [treeObstacle1, treeObstacle2].forEach(treeObstacle => {
            treeObstacle.setOrigin(0.5, 0.5);
            treeObstacle.body.setSize((treeObstacle.displayWidth * 0.8), (treeObstacle.displayHeight * 0.8));
        });
    }
    

    createScoreText() {
        const textX = this.cameras.main.width * 0.10; // 10% från vänster
        const textY = this.cameras.main.height * 0.013; // 5% från toppen
    
        this.scoreText = this.add.text(textX, textY, '0 P', {
            fontSize: `${this.cameras.main.width * 0.07}px`, // 7% av skärmens bredd
            fill: '#000',
            fontFamily: '"Istok Web", sans-serif',
            fontWeight: '700'
        });
    
        this.scoreText.setDepth(1);
    
        this.scale.on('resize', (gameSize) => {
            const textX = gameSize.width * 0.10;
            const textY = gameSize.height * 0.013;
            this.scoreText.setPosition(textX, textY);
            this.scoreText.setFontSize(gameSize.width * 0.05);
        });
    }

    createPausebutton() {
        const buttonX = this.cameras.main.width * 0.85; // 85% åt vänster
        const buttonY = this.cameras.main.height * 0.03; // 3% från toppen
    
        pauseButton = this.add.sprite(buttonX, buttonY, 'avsluta').setInteractive();
        pauseButton.on('pointerdown', this.quitScene, this);
        pauseButton.setDepth(1);
    
        this.scale.on('resize', (gameSize) => {
            const buttonX = gameSize.width * 0.85;
            const buttonY = gameSize.height * 0.03;
            pauseButton.setPosition(buttonX, buttonY);
        });
    }

    addPhysics() {
        // lägg till fysik för objektkollidering
        this.physics.add.overlap(this.player, this.points, this.collectScore, null, this);
        this.physics.add.collider(this.player, this.obstacles, this.hitObstacle, null, this);
        this.physics.add.collider(this.player, this.treeObstacles, this.hitTreeObstacle, null, this);
    }

    playSounds() {
        // ljud effekter
        this.startSound = this.sound.add('startSound');
        this.playsound = this.sound.add('playsound');
        this.stopSound = this.sound.add('stopSound');
        this.startSound.play();
        this.playsound.play();
    }

    spawnObjects() {
        this.spawnPoint();
        this.spawnObstacle();
        this.spawnObstacleTree();
    }

    update() {
        if (this.gameOver) {
            return;
        }

        if (!this.playsound.isPlaying) {
            this.playsound.play();
        }

        this.handleInput();

        this.warpPlayer();

        this.points.children.iterate((point) => {
            if (point) {
                point.y -= this.movementSpeed;
                if (point.y < 0) {
                    point.destroy();
                }
            }
        });

        this.obstacles.children.iterate((obstacle) => {
            if (obstacle) {
                obstacle.y -= this.movementSpeed;
                if (obstacle.y < 0) {
                    obstacle.destroy();
                }
            }
        });

        this.treeObstacles.children.iterate((treeObstacle) => {
            if (treeObstacle) {
                treeObstacle.y -= this.movementSpeed;
                if (treeObstacle.y < 0) {
                    treeObstacle.destroy();
                }
            }
        });
    }

    handleInput() {
         // Kolla för touch input
         if (this.input.pointer1.isDown || this.input.pointer2.isDown) {
            // Kolla om touch är på vänstersida av skärmen
            if (this.input.pointer1.x < this.cameras.main.width / 2
                || (this.input.pointer2.isDown
                && this.input.pointer2.x < this.cameras.main.width / 2)) {
                this.player.setVelocityX(-this.sideSpeed);
                this.player.anims.play('left', true);
            }
            // kolla om touch är på högersida om skärmen
            else if (this.input.pointer1.x >= this.cameras.main.width / 2 
                    || (this.input.pointer2.isDown 
                    && this.input.pointer2.x >= this.cameras.main.width / 2)) {
                this.player.setVelocityX(this.sideSpeed);
                this.player.anims.play('right', true);
            }
        } else {
            // Ingen touch ==> tangentbord
            if (this.cursors.left.isDown) {
                this.player.setVelocityX(-this.sideSpeed);
                this.player.anims.play('left', true);
            } else if (this.cursors.right.isDown) {
                this.player.setVelocityX(this.sideSpeed);
                this.player.anims.play('right', true);
            } else {
                this.player.setVelocityX(0);
                this.player.anims.play('straight', true);
            }
        }
    }

    warpPlayer() {
        //om man vill åka in och ut från skärmen 
        if (this.player.x > this.cameras.main.width) {
            this.player.x = 0;
        } else if (this.player.x < 0) {
            this.player.x = this.cameras.main.width;
        }
    }

    collectScore(player, point) {
        point.destroy();
        this.score += 1;
        this.scoreText.setText(this.score + ' P');


        if (this.score > 0 && this.score % 5 === 0 && this.score < 49) {
            this.movementSpeed += 0.5;
            if (this.sideSpeed > 500) {
                this.sideSpeed += this.sideSpeed * 0.5;
            }

            this.clearIntervals();

            this.spawnObjects();
        }

        if (this.score === 30 && this.gyllenebulle === false) {
            this.spawnGylleneBulle();
        }

        if (this.score >= 50 && !this.gyllenebulle) {
            this.spawnGylleneBulle();
        }

        if (this.score % 10 === 0) {
            this.spawnElk();
        }
    }

    spawnGylleneBulle() {
        let config = this.game.config;
        let x = Phaser.Math.Between(20, config.width - 20); // Adjust as necessary
        let y = config.height; // Starting from the top of the screen

        // Skapa 
        let gyllenebulleSprite = this.physics.add.sprite(x, y, 'gyllenebulle');
        gyllenebulleSprite.play('spin');
        gyllenebulleSprite.setInteractive();

        this.gyllenebulleSprite = gyllenebulleSprite;
        gyllenebulleSprite.setVelocityY(-this.movementSpeed * 30); // Negative for upward movement

        this.physics.add.overlap(this.player, gyllenebulleSprite, this.collectGylleneBulle, null, this);
    }

    collectGylleneBulle(player, gyllenebulle) {
        gyllenebulle.destroy();
        this.score += 10;
        this.gyllenebulle = true;
        sessionStorage.setItem('gyllenebullen', this.gyllenebulle);
        this.scoreText.setText(this.score + ' P');
    }

    hitObstacle(player, obstacle) {
        this.physics.pause();
        player.setTint(0xff0000);
        this.playsound.stop();
        this.gameOver = true;
        this.stopSound.play();
        sessionStorage.setItem('gamescore', this.points);
        this.clearIntervals();
        EventBus.emit('game-over', { points: this.score });
    }

    hitTreeObstacle(player, treeObstacle) {
        this.physics.pause();
        player.setTint(0xff0000);
        this.playsound.stop();
        this.gameOver = true;
        this.stopSound.play();
        sessionStorage.setItem('gamescore', this.points);
        this.clearIntervals();
        EventBus.emit('game-over', { points: this.score });
    }

    spawnElk() {
        let config = this.game.config;
        let elkPosition = [0, (config.width + 5)];
        let y = (config.height / 2) + (config.height / 4);
        let i = Math.floor(Math.random() * 2);
        let elkSprite = this.physics.add.sprite(elkPosition[i], y, 'moose');
        elkSprite.setSize(43, 36);
        elkSprite.play('runningmoose');
        elkSprite.setInteractive();
        elkSprite.setDepth(1);

        this.elkSprite = elkSprite;
        if (elkPosition[i] < (config.width / 2)) {
            elkSprite.setVelocityX(this.movementSpeed * 40)
            elkSprite.setVelocityY(-this.movementSpeed * 60);
            elkSprite.setFlipX(false);
        } else {
            elkSprite.setVelocityX(-this.movementSpeed * 40)
            elkSprite.setVelocityY(-this.movementSpeed * 60);
            elkSprite.setFlipX(true);
        }

        this.physics.add.collider(this.player, elkSprite, this.hitElk, null, this);
    }

    hitElk(player, elk) {
        this.physics.pause();
        player.setTint(0xff0000);
        this.playsound.stop();
        this.gameOver = true;
        this.stopSound.play();
        sessionStorage.setItem('gamescore', this.points);
        this.clearIntervals();
        EventBus.emit('game-over', { points: this.score });
    }

    spawnPoint() {
        let self = this;
        let config = this.game.config;
        this.pointSpawnInterval = setInterval(function () {
            if (self.points.getChildren().length < self.maxPoints) {
                let potentialX = Phaser.Math.Between(20, config.width - 20);
                let potentialY = config.height;
                let tempPoint = self.points.create(potentialX, potentialY, 'point');
                if (self.physics.overlap(tempPoint, self.treeObstacles) ||
                    self.physics.overlap(tempPoint, self.points) ||
                    self.physics.overlap(tempPoint, self.obstacles) ||
                    self.physics.overlap(tempPoint, self.trees)) {
                    tempPoint.destroy();
                } else {
                    tempPoint.setActive(true);
                    tempPoint.setVisible(true);
                }
            }
        }, 500);
    }

    spawnObstacle() {
        let self = this;
        let config = this.game.config;
        this.obstacleSpawnInterval = setInterval(function () {
            if (self.obstacles.getChildren().length < 5) {
                let potentialX = Phaser.Math.Between(40, config.width - 40);
                let potentialY = config.height;

                let tempObstacle = self.obstacles.create(potentialX, potentialY, 'sten');
                if (self.physics.overlap(tempObstacle, self.treeObstacles) ||
                    self.physics.overlap(tempObstacle, self.obstacles) ||
                    self.physics.overlap(tempObstacle, self.points) ||
                    self.physics.overlap(tempObstacle, self.trees)) {
                    tempObstacle.destroy();
                } else {
                    tempObstacle.setActive(true);
                    tempObstacle.setVisible(true);
                }
            }
        }, Phaser.Math.Between(1000, 2000));
    }

    spawnObstacleTree() {
        let self = this;
        let config = this.game.config;
        this.obstacleTreeSpawnInterval = setInterval(function () {
            if (self.treeObstacles.getChildren().length < 5) {
                let potentialX = Phaser.Math.Between(40, config.width - 40);
                let potentialY = config.height;

                let tempObstacle = self.treeObstacles.create(potentialX, potentialY, 'treeObstacle');
                if (self.physics.overlap(tempObstacle, self.treeObstacles) ||
                    self.physics.overlap(tempObstacle, self.points) ||
                    self.physics.overlap(tempObstacle, self.trees) ||
                    self.physics.overlap(tempObstacle, self.obstacles)) {
                    tempObstacle.destroy();
                } else {
                    tempObstacle.setActive(true);
                    tempObstacle.setVisible(true);
                }
            }
        }, Phaser.Math.Between(1000, 2000));
    }

    changeScene() {
        this.scene.start('GameOver');
    }

    quitScene() {
        this.physics.pause();
        this.playsound.stop();
        this.gameOver = true;
        this.stopSound.play();
        sessionStorage.setItem('gamescore', this.points);
        this.clearIntervals();
        EventBus.emit('game-over', { points: this.score });
    }

    // pauseScene: Pausar scenen och låter användaren återuppta
    pauseScene() {
        if (this.gameOver) return;

        if (!this.isGamePaused) {
            this.isGamePaused = true;
            if (pauseButton) {
                pauseButton.setTexture('playButton');
            }

            this.playsound.stop();
            this.game.loop.sleep();

            this.clearIntervals();
        } else {
            this.isGamePaused = false;
            if (pauseButton) {
                pauseButton.setTexture('pauseButton');
            }
            this.playsound.play();
            this.game.loop.wake();

            this.spawnObjects();
        }
    }

    clearIntervals() {
        clearInterval(this.pointSpawnInterval);
        clearInterval(this.obstacleSpawnInterval);
        clearInterval(this.obstacleTreeSpawnInterval);
    }

}