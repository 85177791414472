import React, { useState , useEffect} from "react";
import "./DiscountCode.css";

function DiscountCode() {
    const [copied, setCopied] = useState(false);
    const [discountCode, setDiscountCode] = useState(null);

    // Funktion för att kopiera rabattkoden till urklipp
    const copyDiscountCode = () => {
        navigator.clipboard.writeText(discountCode); // Ersätt med din rabattkod
        setCopied(true);
        // Återställ kopieringsstatus efter 3 sekunder
        setTimeout(() => {
            setCopied(false);

        }, 3000);
    };

    const openLink = () => {
        setTimeout(() => {
            plusRedirects();
            window.open("https://svenskhusman.se/", "_blank");
        }, 1000); // 1 sekunders fördröjning
    };

    const plusRedirects = async () => {
        try {
            const response = await fetch('https://api.husmanspel.se/PlusRedirect', {
                method: 'PUT',
                headers: {
                    'accept': '*/*'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error calling curl command:', error);
        }
    };

    const fetchDiscountcode = async () => {
        const token = sessionStorage.getItem('token')
      try {
          const response = await fetch('https://api.husmanspel.se/GetDc', {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${token}`
              }
          });
          if (response.ok) {
              const data = await response.json();
              setDiscountCode(data);
          } else {
              throw new Error('Failed to fetch current discount');
          }
      } catch (error) {
          console.error('Error fetching discount code:', error);
      }
    };
    useEffect(() => {
      fetchDiscountcode();
    }, []);

    return (
        <div>
            {/* Knapp för att kopiera rabattkoden */}
            <button className={copied ? "buttonGetDisCode copied" : "buttonGetDisCode"} onClick={() => {
                copyDiscountCode(); // Kopiera rabattkoden
                openLink(); // Öppna länken
            }}>
                {copied ? "Kopierad!" : "Kopiera rabattkod"}
            </button>

           
        </div>
    );
}

export default DiscountCode;
