import PropTypes from 'prop-types';
import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import StartGame from './main';
import { EventBus } from './EventBus';
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import React, {useState } from 'react';

export const PhaserGame = forwardRef(function PhaserGame ({ currentActiveScene }, ref)
{
    const navigate = useNavigate();

    useEffect(() => {
        // Function to handle game over and navigate
        const navigateToGameOver = (eventData) => {
            sessionStorage.setItem('gamescore', eventData.points);
            setTimeout(() => {
                navigate('/gameover');
            }, 1500);
        };
        

        EventBus.on('game-over', navigateToGameOver);

        // Clean up the event listener
        return () => {
            EventBus.off('game-over', navigateToGameOver);
        };
    }, [navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    const game = useRef();

    // Create the game inside a useLayoutEffect hook to avoid the game being created outside the DOM
    useLayoutEffect(() => {
        
        if (game.current === undefined)
        {
            game.current = StartGame("game-container");
            
            if (ref !== null)
            {
                ref.current = { game: game.current, scene: null };
            }
        }

        return () => {

            if (game.current)
            {
                game.current.destroy(true);
                game.current = undefined;
            }

        }
    }, [ref]);

    useEffect(() => {

        EventBus.on('current-scene-ready', (currentScene) => {

            if (currentActiveScene instanceof Function)
            {
                currentActiveScene(currentScene);
            }
            ref.current.scene = currentScene;
            
        });

        return () => {

            EventBus.removeListener('current-scene-ready');

        }
        
    }, [currentActiveScene, ref])

    return (
        <div id="game-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh'}}/>
    );

});

// Props definitions
PhaserGame.propTypes = {
    currentActiveScene: PropTypes.func 
}
