import { Home } from "./components/Home";
import Vilkor from "./components/VilkorAndRules";
import Create from "./components/Create";
import { Leaderboard } from "./components/Leaderboard";
import { Admin } from "./components/admin/Admin"
import { ChangePW } from "./components/admin/ChangePW"
import GameOver from "./components/GameOver";
import Game from "./components/Game";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/villkor',
    element: <Vilkor />
  },
  {
    path: '/create-user',
    element: <Create />
  },
  {
    path: '/game',
    element: <Game/>
  },
  {
    path: '/gameover',
    element: <GameOver />
  },
  {
    path: '/leaderboard',
    element: <Leaderboard />
  },
  {
    path: '/admin',
    element: <Admin />
  },
  {
    path: '/changepassword',
    element: <ChangePW />
  }
];

export default AppRoutes;
