import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../form.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isValid = email.includes('@') && email.includes('.');
    setEmailIsValid(isValid);
  }, [email]);

  const handleEmailChange = (e) => {
    const inputValue = e.target.value.replace(/\s/g, '').toLowerCase().trim();
    setEmail(inputValue);
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch(`https://api.husmanspel.se/GetUser?email=${encodeURIComponent(email)}`);

      if (response.ok) {
        const userData = await response.json();
        
        sessionStorage.setItem('username', userData.user.username);
        sessionStorage.setItem('highscore', userData.user.highscore);
        sessionStorage.setItem('id', userData.user.id);
        sessionStorage.setItem('token', userData.token);
        sessionStorage.setItem('firsttime', true);


        navigate('/game');
      } else {
        const errorText = await response.text();
        if (errorText === "Could not get user for provided email") {
          sessionStorage.setItem('email', email);
          navigate('/create-user');
        } else {
          console.error('Unexpected status code:', response.status);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="loginForm">
      <form onSubmit={handleSubmit}>
        <label>
          <p className="blackS">Mail-adress:</p>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            maxLength={40}
            className="input"
            required
          />
        </label>
        <br />
          <div className='checkbox-container'>
            <label className="checkboxLabel">
              <p className="checkbox-text">Jag godkänner <Link to="/villkor" className="blue-text">villkoren</Link> och vill ta del av nyhetsbrevet.*</p>
              <input
                type="checkbox"
                checked={termsChecked}
                onChange={handleTermsChange}
                className='input'
                required
              />
            </label>
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit" style={{ opacity: emailIsValid && termsChecked ? '1' : '0.5', color: '#fff',}}>STARTA</button>
        </form>
      </div>
  );
};

export default LoginForm;
