import React, { useState, useEffect } from 'react';
import './enddate.css';

const EndDate = () => {
    const [remainingTime, setRemainingTime] = useState({
        days: '',
        hours: '',
        minutes: '',
        seconds: ''
    });

    useEffect(() => {
        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.husmanspel.se/GetEnddate');
            if (response.ok) {
                const endDate = await response.json();
                const endDateTime = new Date(endDate).getTime();
                const currentTime = new Date().getTime();
                const timeDifference = endDateTime - currentTime;

                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                setRemainingTime({
                    days: days,
                    hours: hours,
                    minutes: minutes,
                });
            } else {
                console.error('Failed to get date:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching date:', error);
        }
    };

    return (
        <div className='container'>
            <p className='text'>Tävlingen avslutas om:</p>
            <p className='enddate'>{`${remainingTime.days} dagar, ${remainingTime.hours} timmar, ${remainingTime.minutes} minuter`}</p>
        </div>
    );
};

export default EndDate;
