import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Gameover.css';
import Popup from './Popup';
import Popup2 from './Popup2';
import { useNavigate } from "react-router-dom";

const GameOver = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPopup2, setShowPopup2] = useState(false);


    const id = parseInt(sessionStorage.getItem('id'));
    const highscore = sessionStorage.getItem('highscore');
    const gamescore = sessionStorage.getItem('gamescore');
    const username = sessionStorage.getItem('username');
    const gb = sessionStorage.getItem("gyllenebullen");
    const firsttime = sessionStorage.getItem("firsttime");
    const token = sessionStorage.getItem('token');

    const navigate = useNavigate();

    if(username == null || id == null){
       navigate("/");
    }

    useEffect(() => {
        const updateAndFetch = async () => {
            if(username == null || id == null){
                navigate("/");
                return;
            }
            await updateUserScore(id, gamescore, highscore);
            fetchLeaderboard();
            plusGamePlayed();

            if (gb === "true" && firsttime === "true") {
                setShowPopup2(true);
                sessionStorage.setItem('firsttime', false);
            } else if (gb === "true" && firsttime === "false") {
                setShowPopup2(false); // Hide Popup2 if it's not the first time anymore
            }
        };
        updateAndFetch();
    }, [id, gamescore, highscore]);

    const updateUserScore = async (id, gamescore, highscore) => {
        if (parseInt(gamescore) > parseInt(highscore)) {
            try {
                const response = await fetch(`https://api.husmanspel.se/EditScore?id=${id}&score=${gamescore}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    sessionStorage.setItem('highscore', gamescore);
                } else {
                    console.error('Failed to update score:', response.status, response.statusText);
                }
            } catch (error) {
                console.error('Error updating score:', error);
            }
        }
    };

    const fetchLeaderboard = async () => {
        try {
            const response = await fetch('https://api.husmanspel.se/GetLeaderboard');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const sortedData = [...data].sort((a, b) => b.highscore - a.highscore); // Sort data by highscore in descending order

            // Find the index of the session user in the sorted data
            const sessionUserIndex = sortedData.findIndex(user => user.username === username);

            // If session user is within top 3, display them in their position
            if (sessionUserIndex >= 0 && sessionUserIndex < 3) {
                setUsers(sortedData.slice(0, 3));
            } else {
                // If session user is not within top 3, splice them into 4th position with their respective position
                const sessionUser = sortedData[sessionUserIndex];
                const newUserList = [...sortedData];
                newUserList.splice(3, 0, { ...sessionUser, position: sessionUserIndex + 1 });
                setUsers(newUserList.slice(0, 4));
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching leaderboard data:', error);
            setLoading(false);
        }
    };

    const handleAllClick = () => {
        navigate("/leaderboard");
    };

    const plusGamePlayed = async () => {
        try {
            const response = await fetch('https://api.husmanspel.se/PlusGamesPlayed', {
                method: 'PUT',
                headers: {
                    'accept': '*/*',
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error calling curl command:', error);
        }
    };

  
    return (
        <div className="body-gameover">
            {showPopup2 && gb && <Popup2 />}
            {gb && <Popup />}
            <p className="blue">Du fick</p>
            <p className="bigBlueL">{gamescore} poäng</p>
            <img
                className="game_over_graphic"
                src={require('../images/Game_over.png')}
                alt="Svensk Husman"
            />
            <div className='leaderboard'>
                {loading ? (
                    <p className="loading-message"><em>Loading...</em></p>
                ) : (
                    <div className="leaderboard-table-wrapper">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Poäng</th>
                                </tr>
                            </thead>
                            <tbody className="leaderboard-table-body">
                                {users.map((user, index) => (
                                    <tr key={index} className={(user.username === username) ? 'highlighted-row' : ''}>
                                        <td>{user.position ? user.position : index + 1}</td>
                                        <td>{user.username}</td>
                                        <td>{user.highscore}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='blackBox2'>
                            <div className='box2' onClick={handleAllClick}>
                                <p className="whiteL">Se hela</p>
                            </div>
                        </div>
                    </div>
                )}
                <br />
            </div>

            <Link to="/Game">
                <div className='blueBox'>
                    <div className='boxPlayAgain'>
                        SPELA IGEN
                    </div>
                </div>
            </Link>

        </div>
    );
};

export default GameOver;
