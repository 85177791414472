import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import './Admin.css'; // Import the CSS file

export class Admin extends Component {
  static displayName = Admin.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      error: null,
      statistics: null,
      token:  '',

      endDateDate: '', // Separate state for date input
      endDateTime: '',// Separate state for time input
      dbendDateDate: '', // Separate state for date input
      dbendDateTime: '', // Separate state for time input

      currentLimit: null, // Add state for current limit
      newLimit: '', // Add state for new limit input

      currentDiscount:null,
      newDiscount: '',

      prize: null,
      newPrize: '',

      randomWinner: null, // Add state for random winner
      showConfirmationModal: false,
    };
    this.handleLogin = this.handleLogin.bind(this);

    this.reloadStatistics = this.reloadStatistics.bind(this);

    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleSubmitEndDate = this.handleSubmitEndDate.bind(this);

    this.handleSubmitDiscountCode = this.handleSubmitDiscountCode.bind(this);
    this.handleDiscountCodeChange = this.handleDiscountCodeChange.bind(this);

    this.handleSubmitPrize = this.handleSubmitPrize.bind(this);
    this.handlePrizeChange = this.handlePrizeChange.bind(this);

    this.handleSubmitDiscount = this.handleSubmitDiscount.bind(this);
    this.handleDiscountChange = this.handleDiscountChange.bind(this);

    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleSubmitLimit = this.handleSubmitLimit.bind(this);

    this.handleRandomizeWinner = this.handleRandomizeWinner.bind(this);
 
  }

  async handleLogin(event) {
    event.preventDefault();
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;

      try {
          const response = await fetch(`https://api.husmanspel.se/Login?password=${encodeURIComponent(password)}`);

          if (response.ok && username === 'ericblomster') {
              const token = await response.text(); // Get the token directly from the response body
              sessionStorage.setItem('token', token)

              this.setState({ isLoggedIn: true, error: null });
              this.reloadStatistics();
              this.getEndDate();
              this.fetchCurrentDiscountCode();
              this.fetchCurrentDiscount();
              this.fetchCurrentLimit();
              this.fetchPrize();
          } else {
              this.setState({ error: 'Invalid username or password' });
          }
      } catch (error) {
          console.error('Error logging in:', error);
          this.setState({ error: 'An error occurred. Please try again later.' });
      }

  }

    reloadStatistics = async () => {
        const token = sessionStorage.getItem('token')
    try {
        const response = await fetch('https://api.husmanspel.se/GetStatistics', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

      if (response.ok) {
        const statisticsData = await response.json();
        this.setState({ statistics: statisticsData, error: null });
      } else {
        throw new Error('Failed to fetch updated statistics data');
      }
    } catch (error) {
      console.error('Failed to reload statistics:', error);
    }
  };
  

  render() {
      const { isLoggedIn, error, statistics, prize, newPrize, 
              endDateDate, endDateTime, dbendDateDate, dbendDateTime,
              currentDC, newDC, currentDiscount, newDiscount,
              currentLimit, newLimit, randomWinner, showConfirmationModal} = this.state;
    return (
      <div className="admin-container">
        <div className="admin-form-container">
          {!isLoggedIn ? (
            <div>
              <h2>Logga in</h2>
              <form className="admin-login-form" onSubmit={this.handleLogin}>
                <div>
                  <label htmlFor="username">Användarnamn:</label>
                  <input type="text" id="username" name="username" />
                </div>
                <div>
                  <label htmlFor="password">Lösenord:</label>
                  <input type="password" id="password" name="password" />
                </div>
                <button type="submit">Logga in</button>
                {error && <p className="admin-error-message">{error}</p>}
              </form>
            </div>
          ) : (
            <div className='admin-loggedin'>
                            {statistics ? ( // Add a check for statistics object
                                <div>
                                    <p className='blackM'>Fika spelet har spelats {statistics.gamesplayed} gånger</p>
                                    <p className='blackS'>Och personer har dirigerats om till
                                        svenskhusman.se {statistics.redirects} gånger</p>
                                </div>
                            ) : (
                                <p>Loading statistics...</p> // Display a loading message if statistics is null
                            )}

              <div className="black-line"></div>
              <button onClick={() => this.handleGetEmails()}>Ladda ner email-addresser som en .CSV</button>
              <div className="black-line"></div>

              <p className='blackS'>Tävlingen avslutas - {dbendDateDate}, kl {dbendDateTime}</p>
              <div className="admin-form">
                <form onSubmit={this.handleSubmitEndDate}>
                  <div>
                    <label htmlFor="newEndDate">Nytt datum:</label>
                    <input type="date" id="newEndDate" value={endDateDate} onChange={this.handleEndDateChange} />
                  </div>
                  <div>
                    <label htmlFor="newEndTime">Ny tid:</label>
                    <input type="time" id="newEndTime" value={endDateTime} onChange={this.handleEndTimeChange} />
                  </div>
                  <button type="submit">Uppdatera</button>
                </form>
              </div>

              <div className="black-line"></div>

              <div className="admin-form">
                <p className='blackS'>Priset för tävlingen är för tillfället - "{prize}"</p>
                  <form onSubmit={this.handleSubmitPrize}>
                      <div>
                          <label htmlFor="newDC">Nytt pris:</label>
                          <input type="text" id="newDC" value={newPrize} onChange={this.handlePrizeChange} />
                      </div >
                      <button type="submit">Uppdatera</button>
                  </form >
              </div >

              <div className="black-line"></div>

              <div className="admin-form">
                <p className='blackS'>Rabattkoden att hitta i spelet är för tillfället - "{currentDC}"</p>
                  <form onSubmit={this.handleSubmitDiscountCode}>
                      <div>
                          <label htmlFor="newDC">Ny rabattkod:</label>
                          <input type="text" id="newDC" value={newDC} onChange={this.handleDiscountCodeChange} />
                      </div >
                      <button type="submit">Uppdatera</button>
                  </form >
              </div >
              
              <div className="admin-form">
              <p className='blackS'>som ger en rabatt på: {currentDiscount}%</p>
                <form onSubmit={this.handleSubmitDiscount}>
                  <div>
                    <label htmlFor="newLimit">Ny rabatt:</label>
                    <input type="number" id="newDiscount" value={newDiscount} onChange={this.handleDiscountChange} />
                  </div>
                  <button type="submit">Uppdatera</button>
                </form>
              </div>

              <div className="black-line"></div>
              
              <div className="admin-form">
              <p className='blackS'>Poängränsen för att delta i tävlingen är för tillfället - {currentLimit}p</p>
                <form onSubmit={this.handleSubmitLimit}>
                  <div>
                    <label htmlFor="newLimit">Ny poänggräns:</label>
                    <input type="number" id="newLimit" value={newLimit} onChange={this.handleLimitChange} />
                  </div>
                  <button type="submit">Uppdatera</button>
                </form>
              </div>

              <div className="black-line"></div>

              <div className="admin-form">
                <p className='blackS'>Denna drar en slumpmässig användare
                                      som har ett poängrekord högre än poänggränsen</p>
                <button className='randomWinner' onClick={this.handleRandomizeWinner}>Slumpa en vinnare:</button>
                {randomWinner && (
                  <div className='randomWinner'>
                    <p>ID: {randomWinner.id}</p>
                    <p>Username: {randomWinner.username}</p>
                    <p>Email: {randomWinner.emailaddress}</p>
                  </div>
                )}
              </div>

              <div className="black-line"></div>
              <Link to="/changepassword" className="blue-text">Byt lösenord till admin-sidan</Link>
              <div className="black-line"></div>
              <p className='blackS'>
                Om du nollställer spelet så kommer alla användares poängrekord att rensas från databasen. 
                Samt statistik över hur många spel som spelats och omdirigeringar som gjorts.
              </p>
              <button className="resetGame" onClick={this.openConfirmationModal}>
                NOLLSTÄLL SPELET
              </button>
              {/* Modalfönster */}
              {showConfirmationModal && (
                <div className="confirmation-modal">
                  <p>Är du säker på att du vill nollställa spelet?</p>
                  <button className="confirmReset" onClick={this.resetGame}>Ja</button>
                  <button className="cancelReset" onClick={this.closeConfirmationModal}>Avbryt</button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  async handleGetEmails() {
    const token = sessionStorage.getItem('token')
    try {
      const response = await fetch('https://api.husmanspel.se/ExportEmails', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}` // Set the authorization header with the token
        }
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'emails.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        throw new Error('Failed to get emails');
      }
    } catch (error) {
      console.error('Error getting emails:', error);
    }
  }
  
  async getEndDate() {
    try {
      const response = await fetch('https://api.husmanspel.se/GetEnddate');
      if (response.ok) {
        const endDate = await response.text(); // Get response as text
        if (endDate) {
          // Split the date-time string into date and time parts
          const [datePart, timePart] = endDate.split('T');
          // Update state with the separated date and time
          this.setState({ dbendDateDate: datePart.slice(1, ), dbendDateTime: timePart.slice(0, 5) });
        } else {
          throw new Error('Invalid end date format');
        }
      } else {
        throw new Error('Failed to fetch end date');
      }
    } catch (error) {
      console.error('Failed to fetch end date:', error);
    }
  }
  
  
  handleEndDateChange(event) {
    this.setState({ endDateDate: event.target.value });
  }
  handleEndTimeChange(event) {
    this.setState({ endDateTime: event.target.value });
  }

  async handleSubmitEndDate(event) {
    event.preventDefault();
    const { endDateDate, endDateTime } = this.state;
      const endDate = endDateDate + 'T' + endDateTime;
      const token = sessionStorage.getItem('token')

    try {
      const response = await fetch('https://api.husmanspel.se/ChangeEnddate?dt=' + encodeURIComponent(endDate), {
          method: 'PUT',
          headers: {
              'Authorization': `Bearer ${token}` // Set the authorization header with the token
          }
      });

      if (response.ok) {
        console.log('End date updated successfully');

        // Fetch updated statistics data
        await this.getEndDate();
      } else {
        throw new Error('Failed to update end date');
      }
    } catch (error) {
      console.error('Failed to update end date:', error);
    }
  }

    async fetchCurrentDiscountCode() {
    const token = sessionStorage.getItem('token')
    try {
        const response = await fetch('https://api.husmanspel.se/GetDC', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}` // Set the authorization header with the token
            }
        });
        if (response.ok) {
            const DCData = await response.json();
            this.setState({ currentDC: DCData, error: null });
        } else {
            throw new Error('Failed to fetch current limit');
        }
    } catch (error) {
        console.error('Error fetching current limit:', error);
        this.setState({ currentDC: 'Error' });
    }
}
    async handleSubmitDiscountCode(event) {
    const token = sessionStorage.getItem('token')
    event.preventDefault();
    const { newDC } = this.state;

    try {
        const response = await fetch(`https://api.husmanspel.se/ChangeDc?newDc=${newDC}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}` // Set the authorization header with the token
            }
        });

        if (response.ok) {
            console.log('Discount code updated successfully');
            // Fetch updated current discount code after successful update
            await this.fetchCurrentDiscountCode();
        } else {
            throw new Error('Failed to update discount code');
        }
    } catch (error) {
        console.error('Failed to update discount code:', error);
    }
}
handleDiscountCodeChange(event) {
    this.setState({ newDC: event.target.value }); // Uppdatera state för ny rabattkod
}
  async fetchCurrentLimit() {
    try {
      const response = await fetch('https://api.husmanspel.se/GetLimit', {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.ok) {
        const limitData = await response.json();
        this.setState({ currentLimit: limitData, error: null });
      } else {
        throw new Error('Failed to fetch current limit');
      }
    } catch (error) {
      console.error('Error fetching current limit:', error);
      this.setState({ currentLimit: 'Error' });
    }
  }


  async handleLimitChange(event) {
    this.setState({ newLimit: event.target.value });
  }

  async handleSubmitLimit(event) {
    event.preventDefault();
      const { newLimit } = this.state;
      const token = sessionStorage.getItem('token')

    try {
      const response = await fetch(`https://api.husmanspel.se/UpdateLimit?newLimit=${newLimit}`, {
          method: 'PUT',
          headers: {
              'Authorization': `Bearer ${token}` // Set the authorization header with the token
          }
      });

      if (response.ok) {
        console.log('Limit updated successfully');
        // Fetch updated current limit after successful update
        await this.fetchCurrentLimit();
      } else {
        throw new Error('Failed to update limit');
      }
    } catch (error) {
      console.error('Failed to update limit:', error);
    }
  }
    async fetchCurrentDiscount() {
        const token = sessionStorage.getItem('token')
    try {
      const response = await fetch('https://api.husmanspel.se/GetDiscount', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}` // Set the authorization header with the token
        }
      });
      if (response.ok) {
        const discountData = await response.json();
        this.setState({ currentDiscount: discountData, error: null });
      } else {
        throw new Error('Failed to fetch current discount');
      }
    } catch (error) {
      console.error('Error fetching current discount:', error);
      this.setState({ currentDiscount: 'Error' });
    }
  }
  
  async handleDiscountChange(event) {
    this.setState({ newDiscount: event.target.value });
  }
  
  async handleSubmitDiscount(event) {
    event.preventDefault();
      const { newDiscount } = this.state;
      const token = sessionStorage.getItem('token')
  
    try {
      const response = await fetch(`https://api.husmanspel.se/UpdateDiscount?newPercentage=${newDiscount}`, {
          method: 'PUT',
          headers: {
              'Authorization': `Bearer ${token}` // Set the authorization header with the token
          }
      });
  
      if (response.ok) {
        console.log('Discount updated successfully');
        // Fetch updated current discount after successful update
        await this.fetchCurrentDiscount();
      } else {
        throw new Error('Failed to update discount');
      }
    } catch (error) {
      console.error('Failed to update discount:', error);
    }
  }
  async fetchPrize() {
    try {
      const response = await fetch('https://api.husmanspel.se/GetPrize', {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ prize: data, error: null });
      } else {
        throw new Error('Failed to fetch the prize');
      }
    } catch (error) {
      console.error('Error fetching the prize:', error);
      this.setState({ currentLimit: 'Error' });
    }
  }
  handlePrizeChange(event) {
    this.setState({ newPrize: event.target.value }); 
  }
  async handleSubmitPrize(event) {
    const token = sessionStorage.getItem('token')
    event.preventDefault();
    const { newPrize } = this.state;

    try {
        const response = await fetch(`https://api.husmanspel.se/ChangePrize?newPrize=${newPrize}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}` // Set the authorization header with the token
            }
        });

        if (response.ok) {
            console.log('Prize updated successfully');
            await this.fetchPrize();
        } else {
            throw new Error('Failed to update prize');
        }
    } catch (error) {
        console.error('Failed to update prize:', error);
    }
  }
  
    async handleRandomizeWinner() {
        const token = sessionStorage.getItem('token')
    try {
      const response = await fetch('https://api.husmanspel.se/GetWinner', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}` // Set the authorization header with the token

        }
      });
      if (response.ok) {
        const userData = await response.json();
        this.setState({ randomWinner: userData, error: null });
      } else {
        throw new Error('Failed to fetch random user data');
      }
    } catch (error) {
      console.error('Failed to randomize winner:', error);
    }
  }



  // RESET:
  // Hjälpfunktion för att öppna modalfönstret
  openConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  // Hjälpfunktion för att stänga modalfönstret
  closeConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  // Funktion för att nollställa spelet
  resetGame = async () => {
      const token = sessionStorage.getItem('token')
      try {
          const response = await fetch(`https://api.husmanspel.se/ResetStatics`, {
              method: 'PUT',
              headers: {
                  'Authorization': `Bearer ${token}` 
              }
          });

          if (response.ok) {
              console.log('Game reset successfully');
          } else {
              throw new Error('Failed to reset');
          }
      } catch (error) {
          console.error('Failed to reset:', error);
      }
    // Implementera logik för att nollställa spelet här...
    // Stäng modalfönstret efter att åtgärden är klar
    this.closeConfirmationModal();
  };
  
}

