import { Boot } from './scenes/Boot';
import { Game } from './scenes/Game';
import Phaser from 'phaser';
import { Preloader } from './scenes/Preloader';

// Find out more information about the Game Config at:
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config = {
    type: Phaser.AUTO,
    parent: 'game-container',
    roundPixels: false,
    backgroundColor: '#028af8',
    physics: {
        default: 'arcade',
        arcade: {
            debug: false
        }
    },
    scene: [
        Boot,
        Preloader,
        Game,
    ]
};

const StartGame = (parent) => {
    const parentElement = document.getElementById(parent);
    const parentWidth = parentElement.clientWidth;
    const parentHeight = parentElement.clientHeight;

    const maxWidth = 420;
    const maxHeight = 800;

    let width, height;
    if (parentWidth > maxWidth) {
        width = maxWidth;
    } else {
        width = parentWidth;
    }

    if (parentHeight > maxHeight) {
        height = maxHeight;
    } else {
        height = parentHeight;
    }

    const updatedConfig = {
        ...config,
        width: width,
        height: height,
        parent: parent
    };

    return new Phaser.Game(updatedConfig);
}

export default StartGame;
