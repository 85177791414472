// ProfanityList.js

const profanityList = [
    'jävla',
    'jäkla',
    'helvete',
    'hora',
    'idiot',
    'knulla',
    'fuck',
    'fitta',
    'bög',
    'homo',
    'röv',
    'ass',
    'cock',
    'penis',
    'stjärt',
    'anal',
    'onani',
    'masturba',
    'punk',
    'runk',
    'kuk',
    'bajs',
    'kiss',
    'slyna',
    'jävel',
    'n-ordet',
    'nordet',
    'n3g3r',
    'n3ger',
    'neg3r',
    'n3gg3r',
    'neger',
    'negger',
    'nigga',
    'nigger',
    'mulatt',
    'blatte',
    'nazist',
    'nazzi',
    'nazi',
    'luder',
    'äcklig',
    'äckel',
    'kräk',
    'störd',
    'dumhuvud',
    'svin',
    'mongo',
    'cp',
    'faggot',
    'balle',
    'bitch',
    'satan',
    'hitler',
    'putin',
    'hitl3r',
    'kokain',
    'tram',
    'alko',
    'alco',
    'heroin',
    'mdm',
    'sug',
    'blowjob',
    'hasch',
    'weed',
    'crack',
    'pedo',
    'nekro',
    'hoe',
    'rape',
    'rapist',
    'misshand',
    'våldt',
    'ziggenare',
    'skinhead',
    'chigga',
    'coon',
    'sex',
    'incest',
    'insest',
    '8='
    
  ];

export { profanityList };
