
import React, { useState, useEffect } from "react";
import DiscountCode from "./DiscountCode";
import GylleneBulleGif from "../images/Gyllende_Bulle.gif";

function Popup2() {
    const [isOpen, setIsOpen] = useState(true);
    const [discount, setDiscount] = useState(null);
    const [discountCode, setDiscountCode] = useState(null);
    const gb = sessionStorage.getItem("gyllenebullen"); // Get gyllenebullen from sessionStorage

    const closePopup = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (gb === "true") {
            fetchDiscount();
            fetchDiscountcode();
        }
    }, []);

    useEffect(() => {
        // Check if gb is true and if popup is not already open, then open it
        if (gb === "true" && !isOpen) {
            closePopup();
        }
    }, [gb, isOpen]);

    

    const fetchDiscount = async () => {
        const token = sessionStorage.getItem('token')
        try {
            const response = await fetch('https://api.husmanspel.se/GetDiscount', {
                method: 'GET',
                headers: {
                    'accept': 'application/json', // Set appropriate content type
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setDiscount(data);
        } catch (error) {
            console.error('Error fetching discount:', error);
        }
    };

    const fetchDiscountcode = async () => {
        const token = sessionStorage.getItem('token')
        try {
            const response = await fetch('https://api.husmanspel.se/GetDc', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setDiscountCode(data);
            } else {
                throw new Error('Failed to fetch current discount');
            }
        } catch (error) {
            console.error('Error fetching discount code:', error);
        }
    };
    const plusRedirects = async () => {
        try {
            const response = await fetch('https://api.husmanspel.se/PlusRedirect', {
                method: 'PUT',
                headers: {
                    'accept': '*/*'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error calling curl command:', error);
        }
    };

    return (
        <div>

            {isOpen && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={closePopup}>
                            &times;
                        </span>
                        <p className="blackXL">Du har hittat den gyllene bullen!</p>
                        <img id="lussebulle" src={GylleneBulleGif} alt="Gyllene bulle" style={{ maxWidth: '100px', margin: '0 auto' }} />
                        {discountCode && (
                            <p className="blackS">Använd rabattkoden "{discountCode}" för {discount}% rabatt hos <a href="https://svenskhusman.se/" target="_blank" rel="noopener noreferrer" onClick={plusRedirects}>svenskhusman.se</a> </p>
                        )}
                        <div><DiscountCode /> </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Popup2;
