import React,{useState,useRef, useEffect} from "react";
import { PhaserGame } from "./game/PhaserGame";
import { useNavigate } from "react-router-dom";
const Game = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
      // The sprite can only be moved in the MainMenu Scene
  const [canMoveSprite, setCanMoveSprite] = useState(true);
    
  //  References to the PhaserGame component (game and scene are exposed)
  const phaserRef = useRef();

  const changeScene = () => {

      const scene = phaserRef.current.scene;

      if (scene)
      {
          scene.changeScene();
      }
  }

  // Event emitted from the PhaserGame component
  const currentScene = (scene) => {

      setCanMoveSprite(scene.scene.key !== 'Game');
      
  }

  const pauseScene = () => {
      const scene = phaserRef.current.scene;
      if (scene) {
          scene.pauseScene();
      }
  }
  
    const username = sessionStorage.getItem('username');

    if(username == null){
      navigate("/");
    }

    return (
      <PhaserGame ref={phaserRef} currentActiveScene={currentScene}></PhaserGame>
    );
  }

export default Game;
