import React, { Component } from 'react';
import LoginForm from './lForm';
import "./home.css";
import EndDate from './enddate/enddate';

export class Home extends Component {
  static displayName = Home.name;
  
  constructor(props) {
    super(props);
    this.state = {
      currentLimit: null,
      prize: null
    };
    this.plusRedirects = this.plusRedirects.bind(this);
  }

  async fetchCurrentLimit() {
    try {
      const response = await fetch('https://api.husmanspel.se/GetLimit', {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.ok) {
        const limitData = await response.json();
        this.setState({ currentLimit: limitData, error: null });
      } else {
        throw new Error('Failed to fetch current limit');
      }
    } catch (error) {
      console.error('Error fetching current limit:', error);
      this.setState({ currentLimit: 'Error' });
    }
  }
  async fetchPrize() {
    try {
      const response = await fetch('https://api.husmanspel.se/GetPrize', {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ prize: data, error: null });
      } else {
        throw new Error('Failed to fetch the prize');
      }
    } catch (error) {
      console.error('Error fetching the prize:', error);
      this.setState({ currentLimit: 'Error' });
    }
  }
  
  componentDidMount() {
    this.fetchCurrentLimit();
    this.fetchPrize();
  }

  async plusRedirects() {
    try {
      const response = await fetch('https://api.husmanspel.se/PlusRedirect', {
        method: 'PUT',
        headers: {
          'accept': '*/*'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error calling curl command:', error);
    }
  }

  render() {
    const { currentLimit } = this.state;
    const { prize } = this.state;
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('highscore');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('gamescore');
    sessionStorage.removeItem('gyllenebullen');
    sessionStorage.removeItem('Discountcode');
    sessionStorage.removeItem('firsttime');

    return (
      <div className="body">
        <EndDate></EndDate>
        <div className='motive'>
        <p className="blueL">Fikaspelet</p>
        <p className="blackM">
          Få minst {currentLimit} poäng för att vara med och tävla om {prize} hos <a href="https://svenskhusman.se/" target="_blank" rel="noopener noreferrer" onClick={this.plusRedirects}>svenskhusman.se</a>
        </p>
          <img
            id="motive"
            src={require('../images/motive-lusse.png')}
            alt="Svensk Husman"
          />
        <p className='blackS'>Få över 30 poäng och hitta en överraskning</p>
        </div>
        <div className='form-container'>
          <LoginForm />
        </div>
      </div>
    );
  }
}
