import React, { useEffect, Component } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './App.css';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div className="grid-container">
        <ScrollControl />
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </div>
    );
  }
}

const ScrollControl = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/villkor" || location.pathname === "/admin" ) {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [location]);

  return null;
};
