import React, { useState, useEffect } from 'react';

import "./home.css"; // Import the form CSS file
import '../form.css';
import { useNavigate } from 'react-router-dom';
import { profanityList } from './ProfanityList';


const Create = () => {
  const email = sessionStorage.getItem('email');

  const navigate = useNavigate();

  if (email == null) {
    navigate("/");
  }

  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);
  const [existingUsernames, setExistingUsernames] = useState([]);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  useEffect(() => {
    fetchUsernames();
    setShowVideoPlayer(true);
  }, []);

  useEffect(() => {
    setUsername(username);
  }, [username]);

  const fetchUsernames = async () => {
    try {
      const response = await fetch('https://api.husmanspel.se/GetUsernames');
      if (response.ok) {
        const usernames = await response.json();
        setExistingUsernames(usernames);
      } else {
        console.error('Failed to fetch usernames:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching usernames:', error);
    }
  };
  
  // Function to check if the input contains any inappropriate words
  const containsProfanity = (input) => {
    for (let word of profanityList) {
      if (input.includes(word)) {
        return true;
      }
    }
    return false;
  };

  const handleUsernameChange = (e) => {
    const inputValue = e.target.value.replace(/\s/g, '').toLowerCase();
    // Check if username is already taken when input changes
    setUsernameTaken(existingUsernames.includes(inputValue));
    setUsername(inputValue);
    if (containsProfanity(inputValue)) {
      // Handle the presence of inappropriate words (e.g., display an error message)
        setError('Det angivna användarnamnet innehåller otillåtna ord. Vänligen välj ett annat användarnamn.');
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (usernameTaken) {
      return; // Return early if username is taken
    }

    try {
      const response = await fetch(`https://api.husmanspel.se/CreateUser?username=${encodeURIComponent(username)}&email=${encodeURIComponent(email)}`, {
        method: 'POST'
      });

      if (response.ok) {
        console.log('User created successfully');

        // Delete email from sessionStorage
        sessionStorage.removeItem('email');

        // Fetch user data after user creation
        await fetchUserData(email);
      } else {
        console.error('Unexpected status code:', response.status);
        setError('An error occurred while creating the user.');
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setError('An error occurred while creating the user.');
    }
  };

  const fetchUserData = async (email) => {
    try {
      const response = await fetch(`https://api.husmanspel.se/GetUser?email=${encodeURIComponent(email)}`);

      if (response.ok) {
        const userData = await response.json();
        console.log('User Data:', userData);

        // Store user data in sessionStorage
        sessionStorage.setItem('username', userData.user.username);
        sessionStorage.setItem('highscore', userData.user.highscore);
        sessionStorage.setItem('id', userData.user.id);
        sessionStorage.setItem('token', userData.token)
        sessionStorage.setItem('firsttime', true)

        window.location.href = '/game';
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('An error occurred. Please try again later.');
    }
  };
  
  return (
    <div className="body">
      <p className="blueL">Hur du spelar:</p>
      {showVideoPlayer && <img src={require('../images/htp.gif')} id='htpGif' alt="How to play" />}
      <p className="blueL">Skapa en användare:</p>
      <div className="createForm">
        <form onSubmit={handleSubmit}>
          <label>
            <p className="blackS">Användarnamn:</p>
            <input
              type="username"
              value={username}
              onChange={handleUsernameChange}
              maxLength={25}
              className='input'
              required
            />
          </label>
          {usernameTaken && <p className="error">Användarnamnet är redan taget.</p>} 
          <br />
          <br />
          {error && <p className="error">{error}</p>}
          <button type="submit" disabled={usernameTaken|| error !== null} style={{opacity: username? '1' : '0.5', color: '#fff',}}>Starta</button>
        </form>
      </div>
    </div >
  );
};

export default Create;
